import { post } from './HttpClient';

export interface IOSSignParam {
  accessKeyId: string;
  accessKeySecret: string;
  bucket: string;
  dir: string;
}

export interface IOSSignResponseData {
  accessid: string;
  dir: string;
  expire: string;
  host: string;
  policy: string;
  signature: string;
}

export const generateSignature = (data: IOSSignParam) =>
  post<IOSSignResponseData>('/oss', data);

export const delOssDir = (data: IOSSignParam) => post('/deldir', data);
