import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// 官网 https://axios-http.com/zh/docs/intro
// axios中文文档 http://axios-js.com/zh-cn/docs/index.html

// 拦截器
const Interceptors = {
  Request: {
    Success(config: AxiosRequestConfig) {
      return config;
    },
    Fails(error: any) {
      return Promise.reject(error);
    },
  },
  Response: {
    Success(res: AxiosResponse) {
      // !!!
      return res.data;
    },
    Fails(error: AxiosError<any>) {
      // 超出 2xx 范围的状态码都会触发该函数。

      const { response } = error;
      const msg = response?.data?.message || error.message || '服务器异常';

      alert(msg);

      return Promise.reject(response?.data);
    },
  },
};

// TODO
class ServerResponse<T = any> {
  code!: number;
  data!: T;
  message!: string;
  success!: boolean;
}

function newInstance() {
  // 详细配置 https://axios-http.com/zh/docs/req_config
  const instance = axios.create({
    baseURL: '/api',
    timeout: 1000,
    // headers: { "X-Custom-Header": "foobar" },
  });

  // 请求拦截器
  instance.interceptors.request.use(
    Interceptors.Request.Success,
    Interceptors.Request.Fails
  );

  // 响应拦截器
  instance.interceptors.response.use(
    Interceptors.Response.Success,
    Interceptors.Response.Fails
  );
  return instance;
}

function get<T = any>(
  apiPath: string,
  data?: object,
  axoisConfig?: AxiosRequestConfig
): Promise<ServerResponse<T>> {
  return newInstance().get(apiPath, {
    params: data,
    ...axoisConfig,
  });
}

function post<T = any>(
  apiPath: string,
  data?: object,
  axoisConfig?: AxiosRequestConfig
): Promise<ServerResponse<T>> {
  return newInstance().post(apiPath, data, {
    ...axoisConfig,
  });
}

export { get, post };
