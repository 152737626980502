// 拖拽文件的一些封装

// @drop 的处理方式
// const data = await DropFile.parseDropFile(e.dataTransfer);

// input  @change 的处理方式
// const files = e.target.files || e.dataTransfer.files;
// const data = InputFile.fileListToIFile(files);

interface IFile {
  path?: string;
  fullpath: string;
  file: File;

  type: 'file';
  get isFile(): true;
  get isDirectory(): false;
}

interface IDirectory {
  path?: string;
  fullpath: string;
  files: Array<IFile | IDirectory>;

  type: 'dir';
  get isFile(): false;
  get isDirectory(): true;
}

function createIFile(params: {
  path: string;
  fullpath: string;
  file: File;
}): IFile {
  const { path, fullpath, file } = params;

  return {
    path,
    fullpath,
    file,
    type: 'file',
    get isFile(): true {
      return true;
    },
    get isDirectory(): false {
      return false;
    },
  };
}

function createIDirectory(params: {
  path: string;
  fullpath: string;
  files: Array<IFile | IDirectory>;
}): IDirectory {
  const { path, fullpath, files } = params;

  return {
    path,
    fullpath,
    files,
    type: 'dir',
    get isFile(): false {
      return false;
    },
    get isDirectory(): true {
      return true;
    },
  };
}

export const DropFile = {
  relativePath(path: string) {
    if (path.startsWith('/')) {
      if (path.length === 1) return '';

      return path.substring(1);
    }

    return path;
  },

  async parseDropFile(data: DataTransfer) {
    // 不要使用 DataTransfer.files
    // 使用 DataTransfer.items
    // files 的信息不全
    const { items } = data;

    const result: Array<IFile | IDirectory> = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.kind !== 'file') continue;

      // 对 Item 进行一下转换 FileSystemFileEntry/FileSystemDirectoryEntry
      const entry = item.webkitGetAsEntry();
      const rootPath = entry?.fullPath || '/';

      // 对 原始的 FileSystemEntry 进行一次 自己数据格式的转换
      const data = await this.asMyData(rootPath, entry as FileSystemFileEntry);

      if (data) {
        result.push(data);
      }
    }

    return result;
  },

  /**
   * File 转换为 IFile
   *
   * Directory 转换为 IDirectory
   *
   * 数据层级 和 文件目录 一致
   */
  async asMyData(
    parentPath: string,
    entry: FileSystemFileEntry | FileSystemDirectoryEntry
  ): Promise<IFile | IDirectory | null> {
    if (entry?.isFile) {
      const file = await this.loadFile(entry as FileSystemFileEntry);

      if (file.name.startsWith('.')) {
        console.log('忽略文件 > ', file.name);
        return null;
      }

      return createIFile({
        path: parentPath,
        fullpath: entry.fullPath,
        file,
      });
    }

    if (entry?.isDirectory) {
      return await this.loadDir(
        entry.fullPath,
        entry as FileSystemDirectoryEntry
      );
    }

    return null;
  },

  loadFile(entry: FileSystemFileEntry): Promise<File> {
    return new Promise(resolve => {
      entry.file(resolve);
    });
  },

  loadDir(
    parentPath: string,
    entry: FileSystemDirectoryEntry
  ): Promise<IDirectory> {
    return new Promise(resolve => {
      entry.createReader().readEntries(async entrys => {
        const result: IDirectory = createIDirectory({
          path: parentPath,
          fullpath: entry.fullPath,
          files: [],
        });

        for (let i = 0; i < entrys.length; i++) {
          const entry = entrys[i];
          const data = await this.asMyData(
            parentPath,
            entry as FileSystemFileEntry
          );
          if (data) result.files.push(data);
        }

        resolve(result);
      });
    });
  },

  flatten(data: Array<IDirectory | IFile>) {
    const result: Array<IFile> = [];

    const pathRelative = (f: IFile) => {
      if (f.path) f.path = this.relativePath(f.path);
      if (f.fullpath) f.fullpath = this.relativePath(f.fullpath);

      return f;
    };

    const forDirectory = (dir: IDirectory) => {
      for (const f of dir.files) {
        if (f.isFile) {
          result.push(pathRelative(f));
        }

        if (f.isDirectory) {
          forDirectory(f);
        }
      }
    };

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.isFile) {
        result.push(pathRelative(item));
      }

      if (item.isDirectory) {
        forDirectory(item);
      }
    }

    return result;
  },
};

export const InputFile = {
  parseInputFile(files: FileList) {
    throw new Error(
      '因为 files 没有目录的概念，所以现在暂时不考虑这个功能的编写'
    );
  },

  fileListToIFile(files: FileList) {
    const result: Array<IFile> = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      result.push(
        createIFile({
          path: '',
          fullpath: file.webkitRelativePath,
          file,
        })
      );
    }

    return result;
  },
};
