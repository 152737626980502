// local Storage 的封装 ，存储一些用户输入

export const save = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const load = (key: string) => localStorage.getItem(key);

export const loadArray = (key: string): Array<string> => {
  const str = load(key);

  if (!str) return [];

  const result = JSON.parse(str);
  if (!Array.isArray(result)) throw new Error('数据不是 Array，无法添加');

  return result;
};

export const savaBoolean = (key: string, value: boolean) =>
  save(key, value ? 'true' : '');

export const loadBoolean = (key: string) => !!load(key);

/**
 * 以为 Array 的形式，添加一个 value
 */
export const addToArray = (key: string, value: string) => {
  const arr: Array<string> = loadArray(key);

  if (!arr.includes(value)) {
    arr.unshift(value);
    save(key, JSON.stringify(arr));
  }
};

export const getFirstItem = (key: string) => {
  const arr = loadArray(key);

  if (!Array.isArray(arr) || arr.length === 0) {
    return null;
  }

  return arr[0];
};
