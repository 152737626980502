import {
  generateSignature,
  IOSSignResponseData,
  delOssDir,
  IOSSignParam,
} from '@/api/oss';

import { IUploadFile } from './IType';

import axios from 'axios';

/**
 * 上传之前，清空目录
 */
export const deleteOssDirectory = async (data: IOSSignParam) => {
  const { accessKeyId, accessKeySecret, dir, bucket } = data;
  const res = await delOssDir({
    accessKeyId,
    accessKeySecret,
    bucket,
    dir,
  });
  console.log('删除 OSS 目录 > ', res);
};

export const generateOssSignature = async (data: IOSSignParam) => {
  if (!data.dir) data.dir = '';
  return generateSignature(data);
};

export const startUploadFile = async (
  ali: IOSSignParam,
  files: Array<IUploadFile>
) => {
  const resSign = await generateOssSignature(ali);

  const sign = resSign.data;

  if (!sign) throw new Error('阿里配置错误！');

  for (const file of files) {
    if (file.type === '上传成功') continue;
    file.type = '上传中';

    try {
      await uploadFile(sign, file);
      file.type = '上传成功';
    } catch (error) {
      file.type = '上传失败';
    }
  }
};

const uploadFile = async (sign: IOSSignResponseData, ifile: IUploadFile) => {
  // expire
  const { policy, signature, accessid, host, dir } = sign;

  const { file, path } = ifile;

  const fileName = (() => {
    if (!path) throw new Error('错误的文件路径');

    // dist/js/xxx.js 的 dist 是不需要上传上去的！
    const distPathIndex = path.indexOf('/');
    const uppath = path.substring(distPathIndex + 1);

    if (!dir) return uppath;

    let result = '';

    // 因为用户输入的目录是不确定的，所以需要处理一下
    if (dir?.endsWith('/')) result = dir + uppath;
    else result = `${dir}/${uppath}`;

    // 路径只能为相对路径，所以
    if (result.startsWith('/')) return result.substring(1);

    return result;
  })();

  const formData = new FormData();
  formData.append('key', fileName);
  formData.append('name', file.name);
  formData.append('policy', policy);
  formData.append('OSSAccessKeyId', accessid);
  formData.append('success_action_status', '200');
  formData.append('callback', '');
  formData.append('signature', signature);
  formData.append('file', file);

  console.log('formData > ', formData);

  const upOssRes = await axios({
    url: host,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  console.log(upOssRes);

  if (upOssRes.status === 200) {
    return fileName;
  }

  throw new Error('上传失败');
};
